import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

import ropaec from '../assets/images/ropaec.jpg';
import '../assets/styles/Navbar.css';

const Navbar = () => {
    const [showNav, setShowNav] = useState(false);
    const toggleNavBar = () => {
        setShowNav(prev => !prev);
    }
    
    return (
        <nav>
            <img src={ropaec} alt="RonPaul Phoenix Arts Exhibition Concept" />
            <ul className={showNav ? 'show' : ''}>
                <li><NavLink onClick={() => setShowNav(false)} to='/'>Home</NavLink></li>
                <li><NavLink onClick={() => setShowNav(false)} to='/about'>About</NavLink></li>
                <li><NavLink onClick={() => setShowNav(false)} to='/media-and-publications'>Media and Publications</NavLink></li>
                <li><NavLink onClick={() => setShowNav(false)} to='/contact'>Contact us</NavLink></li>
                <li><NavLink onClick={() => setShowNav(false)} to='/donate'>Donate today</NavLink></li>
            </ul>
            <div className="menu-bar" onClick={toggleNavBar}>
                <FaBars />
            </div>
        </nav>
    );
}

export default Navbar;