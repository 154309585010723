import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { PaystackButton } from 'react-paystack'

import '../../assets/styles/Donate/Donate.css'
import { FaTimes } from 'react-icons/fa'

const DonateForm = () => {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [amount, setAmount] = useState(0)
    
    const [error, setError] = useState()

    const [showModal, setShowModal] = useState(false)

    // const navigate = useNavigate()

    // const publicKey = 'pk_test_29954aacdd942e4a702205c55668dacfa515990b'

    // const componentProps = {
    //     email,
    //     amount: amount * 100,
    //     metadata: {
    //         name,
    //     },
    //     publicKey,
    //     text: "Donate",
    //     onSuccess: () => navigate('/'),
    // }

    const handleDonate = (e) => {
        e.preventDefault()

        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    return (
        <div style={{ padding: '20px' }} onSubmit={handleDonate}>
            <form id="donate-form" style={{ margin: '30px auto', maxWidth: '600px' }}>
                {error && <p className='error'>{error}</p>}
                <div className="input-box">
                    <label htmlFor="name">Your name</label>
                    <input type="text" name="name" id="name" value={name} onChange={(e) => {
                        setError('')
                        setName(e.target.value)
                    }} />
                </div>
                <div className="input-box">
                    <label htmlFor="email">Your email</label>
                    <input type="email" name="email" id="email" value={email} onChange={(e) => {
                        setError('')
                        setEmail(e.target.value)
                    }} />
                </div>
                <div className="input-box">
                    <label htmlFor="amount">Amount</label>
                    <input type="number" name="amount" id="amount" value={amount} onChange={(e) => {
                        setError('')
                        setAmount(e.target.value)
                    }} />
                </div>
                {/* <PaystackButton className='paystack-button' {...componentProps} /> */}

                <button className='paystack-button'>Donate</button>
            </form>

            {showModal && (
                <div className="modal-box">
                    <div className="modal">
                        <div className="icon">
                            !
                        </div>
                        <h3 style={{ marginTop: '20px' }}>Donation is not available now</h3>
                        <p>Come back later</p>
                    </div>
                    <div className="close" onClick={handleCloseModal}>
                        <FaTimes />
                    </div>
                </div>
            )}
        </div>
    )
}

export default DonateForm