import React from 'react'
import { galleryImg2 } from '../constants'

const Esmnce = () => {
    return (
        <div className='gallery-items'>
            {galleryImg2.map(image => (
                <div className='gallery-item'>
                    <img src={image.image} alt={image.id} />
                </div>
            ))}
        </div>
    )
}

export default Esmnce