import React from 'react'
import '../assets/styles/News/Rts.css'
import news1 from '../assets/images/news1.jpg';

const Rts = () => {
    return (
        <div className='page'>
            <div className="rts">
                <img src={news1} alt="Report of the technical session" />
                <div className="content">
                    <h1>Report of the technical session</h1>
                    <p style={{ fontWeight: 'bold' }}>Action memorandum on rapid disappearance of endangered species of African long crested Eagles, rocks vulture in Rukuba, Jos, Forest Elephants in Ondo state and Pangolin in FCT and some other parts of Nigeria and its global conern</p>

                    <h3 style={{ fontWeight: 'bold', marginTop: '15px', marginBottom: '15px' }}>SOURCE: RonPaul Phoenix Exhibition Concept</h3>
                    <p>This purpose of this memorandum is to inform the council of the activities of RonPaul Phoenix Concept towards creating awareness on the rapid depletion of the African long crested eagles, rocks vulture, forest elephant and pangolin which have been sighted and discovered under the watchout of our organization in some parts of Nigeria. <br /><br />The Council is invited to note:</p>

                    <ol>
                        <li>That due to the activities of poachers, these animal species are rapidly disappearing from our environment.</li>
                        <li>That if the activities of poachers continue unchecked, the total absence of these animals will create an imbalance in our eco-system and endanger our bio-diversity.</li>
                        <li>That our organization is actively engaged in public sensitization and awareness creation as well as rescue and protection of these species.</li>
                        <li>That we have tabled this issue and related ones through memoranda at the <b>11th</b>, <b>12th</b> and <b>13th</b> National Council Meetings but were stepped down and denying us the synergy through technical assistance from relevant organs of the Federal Ministry of Environment to sustain our continuous quest for preservation of these endangered species.</li>
                        <li>That we have participated in exhibitions at previous Council Meetings where we have showcased some of these species.</li>
                    </ol>

                    <h3 style={{ marginTop: '15px' }}>Prayers:</h3>
                    <p style={{ marginTop: '15px', marginBottom: '15px' }}>Council is urged to request:</p>

                    <ol>
                        <li>The relevant Departments and Agencies of the Federal Ministry of Environment to partner with our organization to stop the depletion of rear species of <b style={{ textTransform: 'uppercase' }}>African long crested eagle, rocks vulture, forest elephant and pangolin</b> in various parts of Nigeria.</li>
                        <li>The Federal as well as affected States to provide necessary assistance and logistics as well as Operational Vehicle to improve the effectiveness of our activities including continuous public sensitization and advocacy to Schools to promote Educational Awareness.</li>
                        <li>Intelligence gathering and exchange of information with <b style={{ textTransform: 'uppercase' }}>Nesrea forestry and national park</b> and other relevant stakeholders both at the Federal and Sub-National levels.</li>
                    </ol>

                    <h3 style={{ marginTop: '15px' }}>Recommended to be Stepped down</h3>
                </div>
            </div>
        </div>
    )
}

export default Rts