import React from 'react'

import Header from '../components/Header'
import Footer from '../components/Footer'
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'

import '../assets/styles/Contact/Contact.css'

const Contact = () => {
    return (
        <div className="page">
            <Header title='Contact us' />
            <div className="contact-page">
                <ContactInfo />
                <ContactForm />
            </div>
            <Footer />
        </div>
    )
}

export default Contact