import React, { useState } from 'react'
import axios from 'axios'

const ContactForm = () => {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [subject, setSubject] = useState()
    const [message, setMessage] = useState()

    const [successMsg, setSuccessMsg] = useState()

    const [error, setError] = useState()

    const [loading, setLoading] = useState(false)

    const handleContact = async (e) => {
        e.preventDefault()

        setLoading(true)

        await axios.post('https://ropaec-server.vercel.app/', { name, email, subject, message })
            .then(res => {
                setSuccessMsg(res.data.message)
                setTimeout(() => {
                    setSuccessMsg('')
                }, 3000);
                setName('')
                setEmail('')
                setSubject('')
                setMessage('')
            })
            .catch(err => {
                setError(err.response.data.error)
                setName('')
                setEmail('')
                setSubject('')
                setMessage('')
            })
            .finally(() => setLoading(false))
    }

    return (
        <form id="contact-form" className='contact-form' onSubmit={handleContact}>
            {error && <p className='error'>{error}</p>}
            {successMsg && <p className='success'>{successMsg}</p>}
            <div className="input-box">
                <label htmlFor="name">Your name</label>
                <input type="text" name="name" id="name" value={name} onChange={(e) => {
                    setError('')
                    setName(e.target.value)
                }} required />
            </div>
            <div className="input-box">
                <label htmlFor="email">Your email</label>
                <input type="email" name="email" id="email" value={email} onChange={(e) => {
                    setError('')
                    setEmail(e.target.value)
                }} required />
            </div>
            <div className="input-box">
                <label htmlFor="subject">Subject</label>
                <input type="text" name="subject" id="subject" value={subject} onChange={(e) => {
                    setError('')
                    setSubject(e.target.value)
                }} required />
            </div>
            <div className="input-box">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" value={message} onChange={(e) => {
                    setError('')
                    setMessage(e.target.value)
                }} required></textarea>
            </div>
            <input type="submit" value={loading ? "Loading" : "Submit"} disabled={loading} />
        </form>
    )
}

export default ContactForm