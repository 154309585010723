import React from 'react';
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import '../assets/styles/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    const date = new Date().getFullYear();
    
    return (
        <footer>
            <p className="footer-text">
                Copyright &copy; {date} | All Rights Reserved | Designed by <Link to='https://www.niphal.com.ng' style={{ color: '#fff' }} className='footer-link'>Niphal</Link>
            </p>
            <div className="social-media">
                <Link to='https://www.facebook.com/profile.php?id=100064801356273'>
                    <FaFacebook />
                </Link>
                <Link to='https://www.youtube.com/@Eagleman-Aaron'>
                    <FaYoutube />
                </Link>
            </div>
        </footer>
    );
}

export default Footer;