import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import DonateForm from '../components/Donate/DonateForm'

const Donate = () => {
    return (
        <div className='page'>
            <Header title='Donate today' para='Support our cause and lend a helping hand' />
            <DonateForm />
            <Footer />
        </div>
    )
}

export default Donate