import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'
import '../../assets/styles/Home/Contact.css';

const Contact = () => {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [subject, setSubject] = useState()
    const [message, setMessage] = useState()

    const [successMsg, setSuccessMsg] = useState()

    const [error, setError] = useState()

    const [loading, setLoading] = useState(false)

    const handleContact = async (e) => {
        e.preventDefault()

        setLoading(true)
        
        await axios.post('https://ropaec-server.vercel.app/', { name, email, subject, message })
            .then(res => {
                setSuccessMsg(res.data.message)
                setTimeout(() => {
                    setSuccessMsg('')
                }, 3000);
                setName('')
                setEmail('')
                setSubject('')
                setMessage('')
            })
            .catch(err => {
                setError(err.response.data.error)
                setName('')
                setEmail('')
                setSubject('')
                setMessage('')
            })
            .finally(() => setLoading(false))
    }

    return (
        <div className="contact-container" id="contact">
            <h2>Contact Us</h2>
            <p style={{ textAlign: 'center', marginBottom: '20px' }}>We're delighted to connect with you. Whether you have questions, ideas, or just want to say hello, we'd love to hear from you.</p>
            <div className="contact">
                <div className="contact-details">
                    <div className="location">
                        <i className="fa fa-map-marker"></i>
                        <div className="location-text">
                            <h3>Location</h3>
                            <p>Ushafa, FCT Abuja</p>
                        </div>
                    </div>
                    <div className="email">
                        <i className="fa fa-envelope"></i>
                        <div className="email-text">
                            <h3>Email</h3>
                            <Link to="mailto:ronpaulexhibition@gmail.com">ronpaulexhibition@gmail.com</Link>
                        </div>
                    </div>
                    <div className="call">
                        <i className="fa fa-phone"></i>
                        <div className="call-text">
                            <h3>Call</h3>
                            <p>+234 703 122 0107</p>
                        </div>
                    </div>
                </div>
                <form id="contact-form" onSubmit={handleContact}>
                    {error && <p className='error'>{error}</p>}
                    {successMsg && <p className='success'>{successMsg}</p>}
                    <div className="input-box">
                        <label htmlFor="name">Your name</label>
                        <input type="text" name="name" id="name" value={name} onChange={(e) => {
                            setError('')
                            setName(e.target.value)
                        }} required />
                    </div>
                    <div className="input-box">
                        <label htmlFor="email">Your email</label>
                        <input type="email" name="email" id="email" value={email} onChange={(e) => {
                            setError('')
                            setEmail(e.target.value)
                        }} required />
                    </div>
                    <div className="input-box">
                        <label htmlFor="subject">Subject</label>
                        <input type="text" name="subject" id="subject" value={subject} onChange={(e) => {
                            setError('')
                            setSubject(e.target.value)
                        }} required />
                    </div>
                    <div className="input-box">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" value={message} onChange={(e) => {
                            setError('')
                            setMessage(e.target.value)
                        }} required></textarea>
                    </div>
                    <input type="submit" value={loading ? "Loading" : "Submit"} disabled={loading} />
                </form>
            </div>
        </div>
    );
}

export default Contact;