import React from 'react';
import Button from './Button';
import image from '../../assets/images/ropaec.jpg';
import ropaecDoc from '../../assets/docs/ropaec_doc.pdf'

const WhoWeAre = () => {
    return (
        <div className='about'>
            <img src={image} alt="Ropaec" />
            <div className='about-content'>
                <h1>Who we are</h1>
                <p>We are RONPAUL PHOENIX ARTS EXHIBITION CONCEPT, an organization dedicated to innovative initiatives that blend entertainment with environmental conservation. Our organization is a pro-environmental organization saddled with the responsibility of advocacy and sensitization on the dangers of unsafe environment especially for our wildlife species going into extinction as a result of human activities and urbanization. <br /> <br />Our organization also specializes in innovative concepts that blend entertainment with cultural and educational experiences. In the course of our work, we developed a mechanism through which humans can be friendly with wild life, interact and play together. Our core values include accountability, transparency, volunteering, partnership and team work</p>
                <a href={ropaecDoc} download style={{ marginTop: '20px' }}>
                    <Button title='Download our documents' />
                </a>
            </div>
        </div>
    );
}

export default WhoWeAre;