import React from 'react';
import '../assets/styles/Header.css';

const Header = ({ title, para }) => {
    return (
        <header>
            <h1>{title}</h1>
            {para && <p>{para}</p>}
        </header>
    );
}

export default Header;