import React from 'react'

const Button = ({ title }) => {
    return (
        <button className='r-btn'>
            {title}
        </button>
    )
}

export default Button