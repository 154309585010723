import React from 'react'
import { FaEnvelope, FaMapMarker, FaPhone } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ContactInfo = () => {
    return (
        <div className='contact-info'>
            <div className="info">
                <div className="info-icon">
                    <FaMapMarker />
                </div>
                <div className="info-text">
                    <h2>Address</h2>
                    <p>Ushafa, FCT Abuja</p>
                </div>
            </div>
            <div className="info">
                <div className="info-icon">
                    <FaEnvelope />
                </div>
                <div className="info-text">
                    <h2>Email</h2>
                    <Link to='mailto:ronpaulexhibition@gmail.com'>ronpaulexhibition@gmail.com</Link>
                </div>
            </div>
            <div className="info">
                <div className="info-icon">
                    <FaPhone />
                </div>
                <div className="info-text">
                    <h2>Phone</h2>
                    <p>+234 703 122 0107</p>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo