import React from 'react'
import { galleryImg3 } from '../constants'

const Rwmbdc = () => {
    return (
        <div className='gallery-items'>
            {galleryImg3.map(image => (
                <div className='gallery-item'>
                    <img src={image.image} alt={image.id} />
                </div>
            ))}
        </div>
    )
}

export default Rwmbdc