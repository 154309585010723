import React from 'react';
import visionImg from '../../assets/images/vision-img.jpg';
import '../../assets/styles/Home/Vision.css';

const Vision = () => {
    return (
        <div className="vision">
            <img src={visionImg} alt="Ropaec" />
            <div className="vision-content">
                <h1>Our Vision</h1>
                <p>To blend conservation and entertainment, creating a future where sustainability is both enjoyable and essential.</p>
            </div>
        </div>
    );
}

export default Vision;