import React from 'react';
import Header from '../components/Header';
import WhoWeAre from '../components/About/WhoWeAre';
import Team from '../components/About/Team';
import Footer from '../components/Footer';

const About = () => {
    return (
        <div className='page'>
            <Header title='About us' para='Learn more about us' />
            <WhoWeAre />
            <Team />
            <Footer />
        </div>
    );
}

export default About;