import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div className='page'>
            <h2>Page does not exist. Back to <Link to='/'>Homepage</Link></h2>
        </div>
    );
}

export default PageNotFound;