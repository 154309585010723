import React from 'react'
import { Link } from 'react-router-dom'

import '../../assets/styles/MediaAndPublication/Gallery.css'
import { galleryImage1, galleryImage2, galleryImage3 } from '../../constants'

const Gallery = () => {
    return (
        <div className='gallery'>
            <h1>Gallery</h1>
            <div className="gallery-division">
                <div className='gallery-divs'>
                    <img src={galleryImage3.thirtyTwo} alt='Ropaec Gallery' />
                    <p>Ropaec world's migratory birds day celebration</p>
                    <Link to='rwmbdc'>View all</Link>
                </div>
                <div className='gallery-divs'>
                    <img src={galleryImage2.twentySeven} alt='Ropaec Gallery' />
                    <p>Exhibition at the 17th meeting of the national council of environment 2024</p>
                    <Link to='esmnce'>View all</Link>
                </div>
                <div className='gallery-divs'>
                    <img src={galleryImage1.one} alt='Ropaec Gallery' />
                    <p>Past events</p>
                    <Link to='past-events'>View all</Link>
                </div>
            </div>
        </div>
    )
}

export default Gallery