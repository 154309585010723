import React from 'react';
import Header from '../components/Header';
import Vision from '../components/Home/Vision';
import Mission from '../components/Home/Mission';
import About from '../components/Home/About';
// import FeaturedNews from '../components/Home/FeaturedNews';
import Contact from '../components/Home/Contact';
import Footer from '../components/Footer';
import News from '../components/MediaAndPublication/News';

const Home = () => {
    return (
        <div className='page'>
            <Header title={`Bridging Conservation and\nEntertainment for a Sustainable Future`} />
            <About />
            <Mission />
            <Vision />
            {/* <FeaturedNews /> */}
            <News title='Featured News' />
            <Contact />
            <Footer />
        </div>
    );
}

export default Home;