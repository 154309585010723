import one from '../assets/images/gallery/one.jpg'
import two from '../assets/images/gallery/two.jpg'
import three from '../assets/images/gallery/three.jpg'
import four from '../assets/images/gallery/four.jpg'
import five from '../assets/images/gallery/five.jpg'
import six from '../assets/images/gallery/six.jpg'
import seven from '../assets/images/gallery/seven.jpg'
import eight from '../assets/images/gallery/eight.jpg'
import nine from '../assets/images/gallery/nine.jpg'
import ten from '../assets/images/gallery/ten.jpg'
import eleven from '../assets/images/gallery/eleven.jpg'
import twelve from '../assets/images/gallery/twelve.jpg'
import thirteen from '../assets/images/gallery/thirteen.jpg'
import fourteen from '../assets/images/gallery/fourteen.jpg'
import fifteen from '../assets/images/gallery/fifteen.jpg'
import sixteen from '../assets/images/gallery/sixteen.jpg'
import seventeen from '../assets/images/gallery/seventeen.jpg'
import eighteen from '../assets/images/gallery/eighteen.jpg'
import nineteen from '../assets/images/gallery/nineteen.jpg'
import twenty from '../assets/images/gallery/twenty.jpg'
import twentyOne from '../assets/images/gallery/twenty-one.jpg'
import twentyTwo from '../assets/images/gallery/twenty-two.jpg'
import twentyThree from '../assets/images/gallery/twenty-three.jpg'
import twentyFour from '../assets/images/gallery/twenty-four.jpg'
import twentyFive from '../assets/images/gallery/twenty-five.jpg'
import twentySix from '../assets/images/gallery/twenty-six.jpg'
import twentySeven from '../assets/images/gallery/twenty-seven.jpg'
import twentyEight from '../assets/images/gallery/twenty-eight.jpg'
import twentyNine from '../assets/images/gallery/twenty-nine.jpg'
import thirty from '../assets/images/gallery/thirty.jpg'
import thirtyOne from '../assets/images/gallery/thirty-one.jpg'
import thirtyTwo from '../assets/images/gallery/thirty-two.jpg'
import thirtyThree from '../assets/images/gallery/thirty-three.jpg'
import thirtyFour from '../assets/images/gallery/thirty-four.jpg'
import thirtyFive from '../assets/images/gallery/thirty-five.jpg'
import thirtySix from '../assets/images/gallery/thirty-six.jpg'
import thirtySeven from '../assets/images/gallery/thirty-seven.jpg'
import thirtyEight from '../assets/images/gallery/thirty-eight.jpg'
import thirtyNine from '../assets/images/gallery/thirty-nine.jpg'
import fourty from '../assets/images/gallery/fourty.jpg'
import fourtyOne from '../assets/images/gallery/fourty-one.jpg'
import fourtyTwo from '../assets/images/gallery/fourty-two.jpg'
import fourtyThree from '../assets/images/gallery/fourty-three.jpg'
import fourtyFour from '../assets/images/gallery/fourty-four.jpg'
import fourtyFive from '../assets/images/gallery/fourty-five.jpg'
import fourtySix from '../assets/images/gallery/fourty-six.jpg'
import fourtySeven from '../assets/images/gallery/fourty-seven.jpg'
import fourtyEight from '../assets/images/gallery/fourty-eight.jpg'
import fourtyNine from '../assets/images/gallery/fourty-nine.jpg'
import fifty from '../assets/images/gallery/fifty.jpg'

export const galleryImage1 = {
    one, 
    two, 
    three, 
    four, 
    five,
    six, 
    seven, 
    eight, 
    nine, 
    ten, 
    eleven, 
    twelve, 
    thirteen, 
    fourteen, 
    fifteen, 
    sixteen, 
    seventeen, 
    eighteen, 
    nineteen, 
    twenty, 
    twentyOne, 
    twentyTwo, 
    twentyThree, 
    twentyFour, 
    twentyFive, 
    twentySix, 
}

export const galleryImage2 = {
    twentySeven, 
    twentyEight, 
    twentyNine, 
    thirty, 
    thirtyOne,
}

export const galleryImage3 = {
    thirtyTwo,
    thirtyThree,
    thirtyFour,
    thirtyFive,
    thirtySix,
    thirtySeven,
    thirtyEight,
    thirtyNine,
    fourty,
    fourtyOne,
    fourtyTwo,
    fourtyThree,
    fourtyFour,
    fourtyFive,
    fourtySix,
    fourtySeven,
    fourtyEight,
    fourtyNine,
    fifty
}

export const galleryImg1 = [
    { id: 1, image: one },
    { id: 2, image: two },
    { id: 3, image: three },
    { id: 4, image: four },
    { id: 5, image: five },
    { id: 6, image: six },
    { id: 7, image: seven },
    { id: 8, image: eight },
    { id: 9, image: nine },
    { id: 10, image: ten },
    { id: 11, image: eleven },
    { id: 12, image: twelve },
    { id: 13, image: thirteen },
    { id: 14, image: fourteen },
    { id: 15, image: fifteen },
    { id: 16, image: sixteen },
    { id: 17, image: seventeen },
    { id: 18, image: eighteen },
    { id: 19, image: nineteen },
    { id: 20, image: twenty },
    { id: 21, image: twentyOne },
    { id: 22, image: twentyTwo },
    { id: 23, image: twentyThree },
    { id: 24, image: twentyFour },
    { id: 25, image: twentyFive },
    { id: 26, image: twentySix },
]

export const galleryImg2 = [
    { id: 1, image: twentySeven }, 
    { id: 2, image: twentyEight }, 
    { id: 3, image: twentyNine }, 
    { id: 4, image: thirty }, 
    { id: 5, image: thirtyOne },
]

export const galleryImg3 = [
    { id: 1, image: thirtyTwo },
    { id: 2, image: thirtyThree },
    { id: 3, image: thirtyFour },
    { id: 4, image: thirtyFive },
    { id: 5, image: thirtySix },
    { id: 6, image: thirtySeven },
    { id: 7, image: thirtyEight },
    { id: 8, image: thirtyNine },
    { id: 9, image: fourty },
    { id: 10, image: fourtyOne },
    { id: 11, image: fourtyTwo },
    { id: 12, image: fourtyThree },
    { id: 13, image: fourtyFour },
    { id: 14, image: fourtyFive },
    { id: 15, image: fourtySix },
    { id: 16, image: fourtySeven },
    { id: 17, image: fourtyEight },
    { id: 18, image: fourtyNine },
    { id: 19, image: fifty }
]