import React from 'react'
import { Link } from 'react-router-dom';
import news1 from '../../assets/images/news1.jpg';
// import news2 from '../../assets/images/news2.jpg';
// import news3 from '../../assets/images/news3.jpg';
// import news4 from '../../assets/images/news4.jpg';

import '../../assets/styles/Home/FeaturedNews.css'

const News = ({ title }) => {
    // const news = [
    //     {
    //         id: 1,
    //         img: news1,
    //         title: 'Ending the year strong',
    //         content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi accusamus omnis sint quas nulla vero incidunt'
    //     },
    //     {
    //         id: 2,
    //         img: news2,
    //         title: 'Ending the year strong',
    //         content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi accusamus omnis sint quas nulla vero incidunt'
    //     },
    //     {
    //         id: 3,
    //         img: news3,
    //         title: 'Ending the year strong',
    //         content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi accusamus omnis sint quas nulla vero incidunt'
    //     },
    //     {
    //         id: 4,
    //         img: news4,
    //         title: 'Ending the year strong',
    //         content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi accusamus omnis sint quas nulla vero incidunt'
    //     },
    // ]

    return (
        <div className='featured-news'>
            <h1>{title}</h1>
            <div className="news">
                {/* {news.map(item => (
                    <div className="news-item" key={item.id}>
                        <img src={item.img} alt={item.title} />
                        <Link to="#"><h2>{item.title}</h2></Link>
                        <p>{item.content}</p>
                    </div>
                ))} */}
                <img src={news1} alt="News" />
                <div className="content">
                    <h2>Report of the technical session</h2>
                    <p>This purpose of this memorandum is to inform the council of the activities of RonPaul Phoenix Concept towards creating awareness on the rapid depletion of the African long crested eagles, rocks vulture, forest elephant and pangolin which have been sighted and discovered under the watchout of our organization in some parts of Nigeria. <Link to='/media-and-publications/report-of-technical-session' style={{ color: 'green' }}>Read more</Link></p>
                </div>
            </div>
        </div>
    )
}

export default News