import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

// layout(s)
import RootLayout from './layouts/RootLayout';

// page(s)
import Home from './pages/Home';
import About from './pages/About';
import MediaAndPublication from './pages/MediaAndPublication';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import PageNotFound from './pages/PageNotFound';
import Rts from './pages/News';
import Rwmbdc from './pages/Rwmbdc';
import Esmnce from './pages/Esmnce';
import PastEvents from './pages/PastEvents';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path='about' element={<About />} />
      <Route path='media-and-publications' element={<MediaAndPublication />} />
      <Route path='media-and-publications/report-of-technical-session' element={<Rts />} />
      <Route path='media-and-publications/rwmbdc' element={<Rwmbdc />} />
      <Route path='media-and-publications/esmnce' element={<Esmnce />} />
      <Route path='media-and-publications/past-events' element={<PastEvents />} />
      <Route path='contact' element={<Contact />} />
      <Route path='donate' element={<Donate />} />
      <Route path='*' element={<PageNotFound />} />
    </Route>
  )
);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
}

export default App;