import React from 'react';
import missionImg from '../../assets/images/mission-img.jpg';
import '../../assets/styles/Home/Mission.css';

const Mission = () => {
    return (
        <div className="mission">
            <div className='mission-content'>
                <h1>Our Mission</h1>
                <p>To exemplify the values of leadership, dedication, and service while actively engaging with and uplifting the community.</p>
            </div>
            <img src={missionImg} alt="Ropaec Mission" />
        </div>
    );
}

export default Mission;