import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import News from '../components/MediaAndPublication/News'
import Gallery from '../components/MediaAndPublication/Gallery'

const MediaAndPublication = () => {
    return (
        <div className='page'>
            <Header title='Media and Publication' para='Check out our news and media' />
            <News title='News' />
            <Gallery />
            <Footer />
        </div>
    )
}

export default MediaAndPublication