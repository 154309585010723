import React from 'react'
import founder from '../../assets/images/team/Founder.jpg'
import countryDirector from '../../assets/images/team/CountryDirector.jpg'
import nationalPro from '../../assets/images/team/NationalPRO.jpg'
import nationalSecretary from '../../assets/images/team/NationalSecretary.jpg'
import directorOfOperations from '../../assets/images/team/DirectorOfOperations.jpg'
import '../../assets/styles/About/Team.css'

const Team = () => {
    const teamMembers = [
        {
            id: 1,
            img: founder,
            name: 'Olubuki Aaron Olamilekan',
            position: 'CEO/Founder'
        },
        {
            id: 2,
            img: countryDirector,
            name: 'Paul Dominic Ochefu',
            position: 'Country Director'
        },
        {
            id: 3,
            img: directorOfOperations,
            name: 'Chukwuma John Chukwuemeka',
            position: 'Director of Operations'
        },
        {
            id: 4,
            img: nationalSecretary,
            name: 'Promise Onyedikachi Moses',
            position: 'National Secretary'
        },
        {
            id: 5,
            img: nationalPro,
            name: 'Ukamaka Rose Raphael',
            position: 'National PRO'
        },
    ]

    return (
        <div className='team'>
            <h1>Our Team</h1>
            <div className="team-members">
                {teamMembers.map(member => (
                    <div className='member'>
                        <img src={member.img} alt={member.name} />
                        <h3>{member.name}</h3>
                        <p>{member.position}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Team